import {
  Alert,
  Card,
  Col,
  Image,
  Modal,
  Row,
  Form,
  Select,
  Input,
  Button,
  InputNumber,
  Upload,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { products, store } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";
import {
  RightCircleOutlined,
  UploadOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { upload } from "@testing-library/user-event/dist/upload";

const { Option } = Select;

function EditProduct() {
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [uploadImages, setUploadImages] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [specifications, setSpecifications] = useState([]);

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const formRef = React.createRef();

  const onFinish = (values) => {
    console.log(values);
  };

  const handleFormSuccess = (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("product_id", product.id);
    formData.append("title", values.title);
    formData.append(
      "store_id",
      JSON.parse(localStorage.getItem("user")).user_vendor[0].id
    );
    formData.append("category_id", values.category_id);
    formData.append("sub_category_id", values.subcategory_id);
    formData.append("mrp", values.mrp);
    formData.append("tax", values.tax);
    formData.append("price", values.price);
    formData.append("description", values.description);
    formData.append("long_description", values.long_description);
    formData.append("delivery_charges", values.delivery_charges);
    formData.append("cod", values.cod === true ? "True" : "False");
    formData.append("in_stock", "True");
    formData.append("active", "True");
    formData.append(
      "specification",
      JSON.stringify({ specification: specifications })
    );

    thumbnail && formData.append("thumbnail", thumbnail);

    products.edit(formData).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success === true) {
        createAlert("success", "Successfully edited product.");
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleFormError = (values) => {
    console.log(values);
  };

  useEffect(() => {
    setLoading(true);
    if (searchParams.get("id")) {
      async function getData() {
        await store
          .get_categories()
          .then((res) => {
            setLoading(false);
            console.log(res.data);
            setProductCategories(res.data);
          })
          .catch((err) => {
            createAlert("error", err.message);
          });
        await products.getSingle(searchParams.get("id")).then((res) => {
          console.log(res);
          setLoading(false);
          if (res.success) {
            setProduct(res.data);
            setProductImages(res.data.product_image);
            form.setFieldsValue({
              title: res.data.title,
              stock_qty: res.data.stock_qty,
              mrp: res.data.mrp,
              tax: res.data.tax,
              price: res.data.price,
              category_id: res.data.category.title,
              subcategory_id: res.data.sub_category.title,
              description: res.data.description,
              long_description: res.data.long_description,
              cod: res.data.cod,
              delivery_charges: res.data.delivery_charges,
            });
            setSpecifications(JSON.parse(res.data.specification).specification);
          } else {
            createAlert("error", res.message);
          }
        });
      }
      getData();
    } else {
      createAlert("error", "Product not found.");
    }
  }, [searchParams, form]);

  const fetchProduct = async () => {
    await products.getSingle(searchParams.get("id")).then((res) => {
      console.log(res);
      setLoading(false);
      if (res.success) {
        setProduct(res.data);
        setProductImages(res.data.product_image);

        form.setFieldsValue({
          // title: res.data.title,
          // stock_qty: res.data.stock_qty,
          // mrp: res.data.mrp,
          // price: res.data.price,
          // branch_id: res.data.branch.branch,
          // category_id: res.data.product_category.title,
          // description: res.data.description,
        });
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleImageUpload = () => {
    if (uploadImages.length > 0) {
      setLoading(true);

      let formData = new FormData();
      formData.append("product_id", product.id);

      for (let item of uploadImages) {
        formData.append("image", item.originFileObj);
      }

      products.uploadImages(formData).then((res) => {
        setLoading(false);
        if (res.success) {
          createAlert("success", "Images uploaded");
          fetchProduct();
        } else {
          createAlert("error", res.message);
        }
      });
    } else {
      createAlert("error", "Please select the images");
    }
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleFieldUpdate = (index, e) => {
    let newArray = [...specifications];
    let el = newArray[index];
    el[e.target.name] = e.target.value;

    setSpecifications(newArray);
    console.log(specifications);
    // let oldArray = specifications;
  };

  const handleRemoveItem = (e) => {
    let temp = [...specifications];
    temp.splice(e, 1);
    setSpecifications(temp);
  };

  const handleCategoryChange = (e) => {
    let sub_categories = productCategories.find((x) => x.id === e);
    // console.log(sub_categories.sub_category);
    setSubCategories(sub_categories.sub_category);
  };

  const handleImageDelete = async () => {
    setLoading(true);
    setModalVisible(false);
    await products.deleteImage(deleteId).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        createAlert("success", "Image successfully deleted.");
        let remaining = productImages.filter((e) => {
          return e.id !== deleteId;
        });
        setProductImages(remaining);
      } else {
        createAlert("error", res.message);
      }
    });
  };

  return (
    <>
      {product && !loading ? (
        <>
          <PageBreadcrumb
            items={[
              { link: "/", title: "Home" },
              { link: "/products", title: "Products" },
              { title: "Edit" },
              { title: product.title },
            ]}
          />
          <div className="d-flex justify-space-between align-content-center align-center">
            <Header title={product.title} />
          </div>
          <Card title="Product Details" bordered={true}>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={handleFormSuccess}
              onFinishFailed={handleFormError}
              autoComplete="off"
              form={form}
              ref={formRef}
            >
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    className="hide-astricks"
                    label="Select Product Category"
                    name="category_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select main category.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select category"
                      onChange={(e) => {
                        handleCategoryChange(e);
                      }}
                    >
                      {productCategories &&
                        productCategories.map((item, index) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="hide-astricks"
                    label="Select Product Sub Category"
                    name="subcategory_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select sub category.",
                      },
                    ]}
                  >
                    <Select placeholder="Select category">
                      {subCategories &&
                        subCategories.map((item, index) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="hide-astricks"
                    label="Product name"
                    name="title"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter product name.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter product name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    className="hide-astricks"
                    label="MRP"
                    name="mrp"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter MRP.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter MRP"
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="hide-astricks"
                    label="Selling price"
                    name="price"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter selling price.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter price"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="hide-astricks"
                    label="Tax"
                    name="tax"
                    hasFeedback
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Tax"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    className="hide-astricks"
                    label="Delivery charges"
                    name="delivery_charges"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter delivery charges.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Delivery charges"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="hide-astricks"
                    label="Cash on delivery"
                    name="cod"
                    hasFeedback
                  >
                    <Switch defaultChecked={product.cod} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                className="hide-astricks"
                label="Product Short Description"
                name="description"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter short description.",
                  },
                ]}
              >
                <Input placeholder="Enter Short Description" />
              </Form.Item>
              <Form.Item
                className="hide-astricks"
                label="Product Long Description"
                name="long_description"
                hasFeedback
                rules={[
                  { required: true, message: "Please enter long desription." },
                ]}
              >
                <Input.TextArea placeholder="Enter Long Description" />
              </Form.Item>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="hide-astricks"
                    label="Product Thumbnail"
                    name="thumbnail"
                    hasFeedback
                  >
                    <Upload
                      maxCount={1}
                      accept=".jpg, .png"
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={(e) => {
                        if (e.fileList.length) {
                          setThumbnail(e.fileList[0].originFileObj);
                        }
                      }}
                    >
                      <Button
                        style={{ minWidth: "100%" }}
                        icon={<UploadOutlined />}
                      >
                        Upload Thumbnail
                      </Button>
                    </Upload>
                  </Form.Item>
                  {product.thumbnail ? (
                    <a
                      target="blank"
                      className="mb-3 block"
                      href={`https://backend.dzone22.com${product.thumbnail}`}
                    >
                      View Existing Thumbail
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <h3 className="text-lg font-semibold">Product Specifications</h3>
              <hr />

              <div className="mt-4">
                {specifications.map((item, index) => {
                  return (
                    <Row
                      className="border border-solid border-slate-200"
                      key={index}
                    >
                      <Col span={11} className="border-r border-slate-200 p-2">
                        <div className=" flex items-center h-full">
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            name="title"
                            value={item.title}
                            className="focus:outline-0"
                            placeholder="Enter title"
                            onChange={(e) => {
                              handleFieldUpdate(
                                specifications.indexOf(item),
                                e
                              );
                            }}
                          />
                        </div>
                      </Col>
                      <Col span={11} className="border-r border-slate-200 p-2">
                        <div className=" flex items-center h-full">
                          <textarea
                            type="text"
                            value={item.description}
                            style={{ width: "100%" }}
                            name="description"
                            className="focus:outline-0"
                            placeholder="Enter Description"
                            onChange={(e) => {
                              handleFieldUpdate(
                                specifications.indexOf(item),
                                e
                              );
                            }}
                          ></textarea>
                        </div>
                      </Col>
                      <Col span={2} className="pl-3">
                        <div className="flex items-center h-full">
                          <PlusCircleOutlined
                            className="text-lg mr-4"
                            onClick={() => {
                              setSpecifications((specifications) => [
                                ...specifications,
                                {
                                  title: "",
                                  description: "",
                                },
                              ]);
                            }}
                          />
                          {index !== 0 ? (
                            <DeleteOutlined
                              onClick={(item, index) => {
                                handleRemoveItem(index);
                              }}
                              className="text-lg"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="mt-4"
                  loading={loading}
                >
                  Edit Product
                  <RightCircleOutlined />
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </>
      ) : (
        ""
      )}

      {!loading ? (
        <Card
          title="Product Images"
          loading={loading}
          className="mt-8"
          bordered={true}
        >
          {productImages && productImages.length > 0 ? (
            <div className="d-flex flex-row mb-5">
              {productImages.map((e) => (
                <div
                  key={e.id}
                  style={{
                    position: "relative",
                  }}
                >
                  <Image
                    width={150}
                    rootClassName="mr-5"
                    style={{ border: "1px solid #eee" }}
                    src={`https://backend.dzone22.com/${e.image}`}
                  ></Image>{" "}
                  <DeleteOutlined
                    onClick={() => {
                      console.log(e.id);
                      setDeleteId(e.id);
                      setModalVisible(true);
                    }}
                    style={{
                      position: "absolute",
                      background: "rgba(238,238,238,.68)",
                      cursor: "pointer",
                      padding: 10,
                      borderRadius: 500,
                      top: 5,
                      right: 25,
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Alert
              message="No images available."
              type="warning"
              className="mb-5"
            />
          )}

          <Upload
            beforeUpload={() => {
              return false;
            }}
            accept=".jpg,.png"
            multiple
            onChange={(e) => {
              if (e.fileList.length > 0) {
                console.log(e.fileList);
                setUploadImages(e.fileList);
              }
            }}
          >
            <Button style={{ minWidth: "100%" }} icon={<UploadOutlined />}>
              Select Images to upload
            </Button>
          </Upload>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="mt-4"
            onClick={handleImageUpload}
          >
            Upload Images
            <RightCircleOutlined />
          </Button>
        </Card>
      ) : (
        ""
      )}
      <Modal
        title="Modal"
        visible={modalVisible}
        onOk={handleImageDelete}
        onCancel={hideModal}
        okButtonProps={{ type: "danger" }}
        okText="Delete Image"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete the image ?</p>
      </Modal>
    </>
  );
}

export default EditProduct;
