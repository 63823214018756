import { Link, Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, Button, Dropdown, notification } from "antd";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  VideoCameraOutlined,
  MenuFoldOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import logo from "../assets/images/logo.png";
import { useEffect, useState } from "react";
import { regInBackend, user } from "../services/vendor.service";

import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";

import OneSignal from "react-onesignal";

const { Header, Content, Footer, Sider } = Layout;

function DashboardLayout({ route }) {
  // --> Starts push notification code //
  const [initialized, setInitialized] = useState(false);

  // Ends push notification code <-- //

  const OneSignalInit = () => {
    window["plugins"].OneSignal.setAppId(
      "506f8d67-268e-4b92-9a38-51e331b272f3"
    );
    window["plugins"].OneSignal.setNotificationOpenedHandler(function (
      jsonData
    ) {
      console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
    });

    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(
      function (accepted) {
        console.log("User accepted notifications: " + accepted);
      }
    );
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      OneSignalInit();
      window["plugins"].OneSignal.getDeviceState(async (state) => {
        console.log(state.userId);
        await regInBackend(state.userId).then((res) => {
          console.log("push token response", res);
        });
      });
    } else {
      OneSignal.init({
        appId: "506f8d67-268e-4b92-9a38-51e331b272f3",
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerPath: "/",
      }).then(() => {
        setInitialized(true);
        OneSignal.getUserId(async (e) => {
          localStorage.setItem("device_token", e);
          await regInBackend(e).then((res) => {
            console.log("push token response", res);
          });
        });
        OneSignal.showSlidedownPrompt().then(() => {
          console.log("one signal is booming!");
        });
      });
    }
  }, []);

  const navigate = useNavigate();
  const [siderHidden, setSiderHidden] = useState(false);
  const [contentLeftMargin, setContentLeftMargin] = useState(250);

  const get_vendor_details = async () => {
    // await user.get().then((res) => {
    //   // console.log(res);
    //   // localStorage.setItem("user", JSON.stringify(res));
    //   // localStorage.setItem("store", res?.user_vendor[0]?.id);
    // });
  };

  useEffect(() => {
    // get_vendor_details();
  }, []);

  const hideMenu = () => {
    setContentLeftMargin(contentLeftMargin === 250 ? 0 : 250);
    setSiderHidden(!siderHidden);
  };

  //Dropdown menu + Logout functionality
  //testing

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    openNotification("success", "Logout Successful");
    navigate("login");
  };
  //Notification
  const openNotification = (type, message) => {
    if (type === "error") {
      notification.error({
        message: "Error",
        description: `${message}`,
        position: "top",
      });
    }
    if (type === "success") {
      notification.success({
        message: "Success",
        description: `${message}`,
        position: "top",
      });
    }
  };
  const dropdDownMenu = (
    <Menu>
      <Menu.Item key={1}>
        <span
          rel="noopener noreferrer"
          onClick={() => {
            navigate("/edit-profile");
          }}
        >
          My Account
        </span>
      </Menu.Item>
      <Menu.Item key={2}>
        <span rel="noopener noreferrer" onClick={handleLogout}>
          Sign Out
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout hasSider>
        <Sider
          trigger={null}
          breakpoint="lg"
          collapsed={siderHidden}
          collapsedWidth={0}
          width={250}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            background: "white",
            left: 0,
            top: 0,
            bottom: 0,
          }}
          onCollapse={(collapsed, type) => {
            setSiderHidden(true);
            setContentLeftMargin(0);
          }}
        >
          <div className="logo" style={{ padding: 20 }}>
            <h1 style={{ fontWeight: "bolder", fontSize: 28 }}> DZone22 </h1>
          </div>
          <Menu mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item
              onClick={() => {
                setContentLeftMargin(0);
                setSiderHidden(true);
              }}
              key="1"
              icon={<UserOutlined />}
            >
              <Link to="/">Dashboard</Link>
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                setContentLeftMargin(0);
                setSiderHidden(true);
              }}
              icon={<UploadOutlined />}
            >
              <Link to="/orders/">Pickup orders</Link>
            </Menu.Item>
            <Menu.Item
              key="00"
              onClick={() => {
                setContentLeftMargin(0);
                setSiderHidden(true);
              }}
              icon={<UploadOutlined />}
            >
              <Link to="/delivery-list/">Your Deliveries</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            marginLeft: contentLeftMargin,
            height: "calc(100vh - 70px)",
          }}
        >
          <Header
            style={{
              padding: "0 20px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="border-b border-slate-300"
          >
            <div className="flex-row-center">
              <Button
                type="text"
                size="large"
                onClick={hideMenu}
                icon={<MenuFoldOutlined />}
              ></Button>
              <h2 className="mb-0 ml-3 text-lg font-extrabold">
                Dzone Delivery
              </h2>
            </div>

            <Dropdown overlay={dropdDownMenu}>
              <Button
                shape="circle"
                type="primary"
                icon={<UserOutlined />}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              ></Button>
            </Dropdown>
          </Header>

          <Content
            style={{ padding: 10, paddingBottom: 100, overflowY: "auto" }}
          >
            <div style={{ paddingLeft: 24, paddingRight: 24 }}>
              <Outlet />
            </div>
          </Content>

          {/* <Footer
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 1,
              width: "100%",
              background: "white",
            }}
          >
            Dzone ©2022. Created by Kingwizards.
          </Footer> */}
        </Layout>
      </Layout>
    </>
  );
}

export default DashboardLayout;
